<template>
	<div class="w-full h-screen">
		<ClientOnly>
			<SplitView
				v-show="!openBatchActionWorkflow"
				:id="SPLIT_VIEW_ID"
				ref="splitView"
				:component-a-class="componentAClass"
				:component-b-class="componentBClass"
				:hide-component-a="!showMap"
				:loading="loading"
				:direction="direction"
			>
				<template #componentA="{componentAHeight}">
					<MapboxMapAccounts
						:markers="mapboxMarkers"
						:polygons="zones"
						:component-height="componentAHeight"
						:focused-zone-id="focusedZoneId"
						:active-marker-id="activeMarkerId"
						:active-filters="activePresetOptions"
						:show-table-markers="showTableMarkers"
						:table-marker-ids="tableData?.map((row: TableRow) => row?.id) || []"
						@set-focused-zone-id="focusedZoneId = $event"
						@set-active-marker-id="activeMarkerId = $event"
						@set-table-data-from-lasso="setTableDataFromLasso($event)"
					/>
				</template>
				<template #componentB="{componentBHeight}">
					<Table
						id="accountsTable"
						:columns="ACCOUNTS_TABLE_COLUMNS"
						:default-columns="ACCOUNTS_TABLE_DEFAULT_COLUMNS"
						:filters="ACCOUNTS_TABLE_FILTERS"
						:table-data="tableData"
						:total-items="totalItems"
						:per-page="50"
						pagination
						default-sort-order-desc
						hide-header
						search-bar
						:allow-table-export="hasAccountsExportPermission"
						:allow-row-export="hasAccountsExportPermission"
						per-page-select
						column-select
						multi-filter-select
						cell-size-select
						batch-select
						:batch-action-menu-options="ACCOUNTS_BATCH_ACTION_MENU_OPTIONS"
						:is-loading="isLoading"
						:presets-loading="zonesLoading"
						:show-empty-state="showEmptyState"
						:preset-filters="presetFilters"
						empty-state-message="No Results"
						:link-column-keys="['id', 'name', 'address', 'neighborhood', 'phone']"
						link-text-join=" | "
						class="mt-4"
						:reset-batch-selections="resetBatchSelections"
						:component-height="componentBHeight"
						@batch-select-reset="resetBatchSelections = false"
						@active-preset-options="handleActivePresetOptionsChanged"
						@fetch-table-data="handleRefetchAccounts"
						@start-batch-action-workflow="batchActionTableData = $event"
						@columns-change="setActiveColumnsInBatchWorkflow($event)"
					>
						<template #extraControlsOutermostTopRight>
							<div class="flex mt-auto gap-x-4">
								<FormSwitch
									v-if="showMap"
									id="showAllMapMarkersSwitch"
									v-model="showTableMarkers"
									label="Table Markers"
									track-color="bg-mx-gray-200 dark:bg-mx-green-700 dark:group-hover:bg-mx-green-600 group-hover:bg-mx-gray-300"
								/>
							</div>
							<div class="flex mt-auto gap-x-4">
								<FormSwitch
									id="showMapSwitch"
									v-model="showMap"
									label="Map"
									track-color="bg-mx-gray-200 dark:bg-mx-green-700 dark:group-hover:bg-mx-green-600 group-hover:bg-mx-gray-300"
								/>
							</div>
						</template>
						<template #id="{ row }">
							<div class="flex items-center">
								<span>
									{{ row.id }}
								</span>
								<ClientOnly>
									<!-- TODO: i18n -->
									<IconWrenches
										v-if="showPriorityInstallIcon(row)"
										v-tooltip="{ content: 'Priority Install' }"
										class="w-5 h-5 ml-1 text-mx-yellow"
									/>
								</ClientOnly>
							</div>
						</template>
						<template #address="{ row }">
							<button
								class="link-text"
								@click.stop.prevent="setActiveMarker(row.id)"
							>
								{{ row.address }}
							</button>
						</template>
						<template #accountStatus="{ row }">
							<div class="flex">
								<div
									:class="[
										'px-2.5 py-0.5 rounded justify-center items-center flex',
										isActive(row.accountStatus) ? 'bg-green-200 dark:bg-mx-green-500/80 ' : 'bg-mx-gray-100 dark:bg-mx-green-600'
									]"
								>
									<span
										:class="[
											'text-center text-xs font-medium leading-none capitalize mt-[1px]',
											isActive(row.accountStatus) ? 'text-green-800 dark:text-mx-green-900' : 'text-mx-green-800 dark:text-mx-gray-400'
										]"
									>
										{{ row.accountStatus }}
									</span>
								</div>
							</div>
						</template>
						<template #created="{ row }">
							{{ row.created }}
						</template>
						<template #insideInstalled="{ row }">
							<div class="flex justify-center w-full">
								<span
									:class="[
										isTrue(row.insideInstalled) ? 'bg-white dark:bg-mx-green-900' : 'bg-white dark:bg-mx-gray-400',
										'w-6 h-6 transform scale-75 rounded-full'
									]"
								>
									<CheckCircleIcon
										v-if="isTrue(row.insideInstalled)"
										class="w-full h-full transform scale-150 text-mx-green-500 dark:text-mx-green-500/80"
										aria-hidden="true"
									/>
									<XCircleIcon
										v-else
										class="w-full h-full text-red-500 transform scale-150 dark:text-red-800"
										aria-hidden="true"
									/>
								</span>
							</div>
						</template>
						<template #outsideInstalled="{ row }">
							<div class="flex justify-center w-full">
								<span
									:class="[
										isTrue(row.outsideInstalled) ? 'bg-white dark:bg-mx-green-900' : 'bg-white dark:bg-mx-gray-400',
										'w-6 h-6 transform scale-75 rounded-full'
									]"
								>
									<CheckCircleIcon
										v-if="isTrue(row.outsideInstalled)"
										class="w-full h-full transform scale-150 text-mx-green-500 dark:text-mx-green-500/80"
										aria-hidden="true"
									/>
									<XCircleIcon
										v-else
										class="w-full h-full text-red-500 transform scale-150 dark:text-red-800"
										aria-hidden="true"
									/>
								</span>
							</div>
						</template>
						<template #siteSurveyed="{ row }">
							<div class="flex justify-center w-full">
								<span
									:class="[
										isTrue(row.siteSurveyed) ? 'bg-white dark:bg-mx-green-900' : 'bg-white dark:bg-mx-gray-400',
										'w-6 h-6 transform scale-75 rounded-full'
									]"
								>
									<CheckCircleIcon
										v-if="isTrue(row.siteSurveyed)"
										class="w-full h-full transform scale-150 text-mx-green-500 dark:text-mx-green-500/80"
										aria-hidden="true"
									/>
									<XCircleIcon
										v-else
										class="w-full h-full text-red-500 transform scale-150 dark:text-red-800"
										aria-hidden="true"
									/>
								</span>
							</div>
						</template>
						<template #cableSpliced="{ row }">
							<div class="flex justify-center w-full">
								<span
									:class="[
										isTrue(row.cableSpliced) ? 'bg-white dark:bg-mx-green-900' : 'bg-white dark:bg-mx-gray-400',
										'w-6 h-6 transform scale-75 rounded-full'
									]"
								>
									<CheckCircleIcon
										v-if="isTrue(row.cableSpliced)"
										class="w-full h-full transform scale-150 text-mx-green-500 dark:text-mx-green-500/80"
										aria-hidden="true"
									/>
									<XCircleIcon
										v-else
										class="w-full h-full text-red-500 transform scale-150 dark:text-red-800"
										aria-hidden="true"
									/>
								</span>
							</div>
						</template>
						<template #cablePulled="{ row }">
							<div class="flex justify-center w-full">
								<span
									:class="[
										isTrue(row.cablePulled) ? 'bg-white dark:bg-mx-green-900' : 'bg-white dark:bg-mx-gray-400',
										'w-6 h-6 transform scale-75 rounded-full'
									]"
								>
									<CheckCircleIcon
										v-if="isTrue(row.cablePulled)"
										class="w-full h-full transform scale-150 text-mx-green-500 dark:text-mx-green-500/80"
										aria-hidden="true"
									/>
									<XCircleIcon
										v-else
										class="w-full h-full text-red-500 transform scale-150 dark:text-red-800"
										aria-hidden="true"
									/>
								</span>
							</div>
						</template>
						<template #cableTerminated="{ row }">
							<div class="flex justify-center w-full">
								<span
									:class="[
										isTrue(row.cableTerminated) ? 'bg-white dark:bg-mx-green-900' : 'bg-white dark:bg-mx-gray-400',
										'w-6 h-6 transform scale-75 rounded-full'
									]"
								>
									<CheckCircleIcon
										v-if="isTrue(row.cableTerminated)"
										class="w-full h-full transform scale-150 text-mx-green-500 dark:text-mx-green-500/80"
										aria-hidden="true"
									/>
									<XCircleIcon
										v-else
										class="w-full h-full text-red-500 transform scale-150 dark:text-red-800"
										aria-hidden="true"
									/>
								</span>
							</div>
						</template>
						<template #onboardingCompleted="{ row }">
							<div class="flex justify-center w-full">
								<span
									:class="[
										isTrue(row.onboardingCompleted) ? 'bg-white dark:bg-mx-green-900' : 'bg-white dark:bg-mx-gray-400',
										'w-6 h-6 transform scale-75 rounded-full'
									]"
								>
									<CheckCircleIcon
										v-if="isTrue(row.onboardingCompleted)"
										class="w-full h-full transform scale-150 text-mx-green-500 dark:text-mx-green-500/80"
										aria-hidden="true"
									/>
									<XCircleIcon
										v-else
										class="w-full h-full text-red-500 transform scale-150 dark:text-red-800"
										aria-hidden="true"
									/>
								</span>
							</div>
						</template>
						<template #moveInDate="{ row, search }">
							<div class="flex items-center">
								<button
									class="flex mr-2 link-text"
									@click.stop.prevent="search('address', row.address)"
								>
									{{ row.moveInDate }}
								</button>
								<ExclamationTriangleIcon
									v-if="movingInThisMonth(row.moveInDate)"
									class="w-3 h-3 text-mx-orange animate-ping-twice"
								/>
							</div>
						</template>
						<template #phone="{ row }">
							<a
								:href="`tel:${row.phone}`"
								class="link-text"
								@click.stop
							>
								{{ row.phone }}
							</a>
						</template>
						<template #email="{ row }">
							<a
								:href="`mailto:${row.email}`"
								class="link-text"
								@click.stop
							>
								{{ row.email }}
							</a>
						</template>
						<template #surveyStatus="{ row }">
							<div class="flex">
								<div
									:class="[
										'px-2.5 py-0.5 rounded justify-center items-center flex',
										row.surveyStatus === COMPLETED ? 'bg-[#006DFF]/50 dark:bg-[#006DFF]/50' : '',
										row.surveyStatus === SCHEDULED ? 'bg-[#E9A337]/75 dark:bg-[#E9A337]/75' : '',
										row.surveyStatus === NEEDED ? 'bg-[#FB2702]/50 dark:bg-[#FB2702]/50' : ''
									]"
								>
									<span
										:class="[
											'text-center text-xs font-medium leading-none capitalize mt-[1px] text-white',
											row.surveyStatus === COMPLETED ? 'dark:text-mx-gray-300' : '',
											row.surveyStatus === SCHEDULED ? 'dark:text-mx-green-900' : '',
											row.surveyStatus === NEEDED ? 'dark:text-mx-gray-300' : ''
										]"
									>
										{{ row.surveyStatus }}
									</span>
								</div>
							</div>
						</template>
						<template #insideInstallStatus="{ row }">
							<div class="flex">
								<div
									:class="[
										'px-2.5 py-0.5 rounded justify-center items-center flex',
										row.insideInstallStatus === COMPLETED ? 'bg-[#006DFF]/50 dark:bg-[#006DFF]/50' : '',
										row.insideInstallStatus === SCHEDULED ? 'bg-[#E9A337]/75 dark:bg-[#E9A337]/75' : '',
										row.insideInstallStatus === NEEDED ? 'bg-[#FB2702]/50 dark:bg-[#FB2702]/50' : ''
									]"
								>
									<span
										:class="[
											'text-center text-xs font-medium leading-none capitalize mt-[1px] text-white',
											row.insideInstallStatus === COMPLETED ? 'dark:text-mx-gray-300' : '',
											row.insideInstallStatus === SCHEDULED ? 'dark:text-mx-green-900' : '',
											row.insideInstallStatus === NEEDED ? 'dark:text-mx-gray-300' : ''
										]"
									>
										{{ row.insideInstallStatus }}
									</span>
								</div>
							</div>
						</template>
						<template #activationDate="{ row }">
							<div class="text-center">
								{{ row.activatedTime || '-' }}
							</div>
						</template>
						<template #mainlineCompleted="{ row }">
							<div class="flex justify-center w-full">
								<div
									:class="[
										isTrue(row.mainlineCompleted) ? 'bg-white dark:bg-mx-green-900' : 'bg-white dark:bg-mx-gray-400',
										'w-6 h-6 transform scale-75 rounded-full'
									]"
								>
									<CheckCircleIcon
										v-if="isTrue(row.mainlineCompleted)"
										class="w-full h-full transform scale-150 text-mx-green-500 dark:text-mx-green-500/80"
										aria-hidden="true"
									/>
									<XCircleIcon
										v-else
										class="w-full h-full text-red-500 transform scale-150 dark:text-red-800"
										aria-hidden="true"
									/>
								</div>
							</div>
						</template>
						<template #hasEquipment="{ row }">
							<div class="flex justify-center w-full">
								<div
									:class="[
										isTrue(row.hasEquipment) ? 'bg-white dark:bg-mx-green-900' : 'bg-white dark:bg-mx-gray-400',
										'w-6 h-6 transform scale-75 rounded-full'
									]"
								>
									<CheckCircleIcon
										v-if="isTrue(row.hasEquipment)"
										class="w-full h-full transform scale-150 text-mx-green-500 dark:text-mx-green-500/80"
										aria-hidden="true"
									/>
									<XCircleIcon
										v-else
										class="w-full h-full text-red-500 transform scale-150 dark:text-red-800"
										aria-hidden="true"
									/>
								</div>
							</div>
						</template>
						<template #delinquent="{ row }">
							<div class="flex justify-center w-full">
								<div
									:class="[
										isTrue(row.isDelinquent) ? 'bg-white dark:bg-mx-green-900' : 'bg-white dark:bg-mx-gray-400',
										'w-6 h-6 transform scale-75 rounded-full'
									]"
								>
									<CheckCircleIcon
										v-if="isTrue(row.isDelinquent)"
										class="w-full h-full transform scale-150 text-mx-green-500 dark:text-mx-green-500/80"
										aria-hidden="true"
									/>
									<XCircleIcon
										v-else
										class="w-full h-full text-red-500 transform scale-150 dark:text-red-800"
										aria-hidden="true"
									/>
								</div>
							</div>
						</template>
						<template #hasLogin="{ row }">
							<div class="flex justify-center w-full">
								<div
									:class="[
										isTrue(row.hasLogin) ? 'bg-white dark:bg-mx-green-900' : 'bg-white dark:bg-mx-gray-400',
										'w-6 h-6 transform scale-75 rounded-full'
									]"
								>
									<CheckCircleIcon
										v-if="isTrue(row.hasLogin)"
										class="w-full h-full transform scale-150 text-mx-green-500 dark:text-mx-green-500/80"
										aria-hidden="true"
									/>
									<XCircleIcon
										v-else
										class="w-full h-full text-red-500 transform scale-150 dark:text-red-800"
										aria-hidden="true"
									/>
								</div>
							</div>
						</template>
					</Table>
				</template>
			</SplitView>
		</ClientOnly>
		<template v-if="openBatchActionWorkflow">
			<BatchActionWorkflow
				v-if="hasWorkflowData"
				:batch-action-table-data="batchActionTableData"
				:default-columns="batchActionTableDefaultColumns"
				:open-batch-action-workflow="openBatchActionWorkflow"
				:actions="ACCOUNTS_BATCH_ACTIONS"
				:steps="steps"
				:step-components="stepComponents"
				:loading="batchActionLoading"
				:action-completed="batchActionSuccess"
				:action-failed="batchActionError"
				:skipped-accounts="skippedAccounts"
				@close="handleBatchActionClose"
				@submit="handleBatchActionSubmit($event)"
				@reset="handleBatchActionReset"
			/>
		</template>
	</div>
</template>

<script setup lang="ts">
import { XCircleIcon, CheckCircleIcon } from '@heroicons/vue/20/solid'
import type {
	TableColumn,
	TableFilter,
	PresetFilter,
	BatchAction,
	BatchActionForm,
	BatchActionTableData,
	TableRow
} from '@/components/table/types'
import {
	ACCOUNTS_TABLE_FILTERS,
	ACCOUNTS_TABLE_COLUMNS,
	ACCOUNTS_TABLE_DEFAULT_COLUMNS,
	ACCOUNTS_TABLE_PRESET_FILTERS,
	ACCOUNTS_BATCH_ACTION_MENU_OPTIONS
} from '@/constants/accounts/table'
import { useAccounts } from '@/composables/useAccounts'
import { useZones } from '@/composables/useZones'
import { useAuth } from '@/composables/useAuth'
import type { AccountRequestBody } from '@/composables/useAccounts'
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline'
import {
	COMPLETED,
	NEEDED,
	SCHEDULED,
	INACTIVE,
	ACTIVE
} from '@/constants/accounts/statuses'
import ChooseAccounts from '@/components/batchAction/steps/accounts/ChooseAccounts.vue'
import ChooseAction from '@/components/batchAction/steps/accounts/ChooseAction.vue'
import Confirmation from '@/components/batchAction/steps/accounts/Confirmation.vue'
// import PlaceholderStepComponent from '@/components/mock/PlaceholderStepComponent.vue'
import { ACCOUNTS_BATCH_ACTIONS, ACCOUNTS_BATCH_ACTION_IDS } from '@/constants/accounts/table'
import { useAccountStatus } from '@/composables/useAccountStatus'
import type { Feature, Geometry, GeoJsonProperties } from 'geojson'

const ALL_STEP_COMPONENTS = {
	[ACCOUNTS_BATCH_ACTION_IDS.SEND_SCHEDULING_MESSAGE]: {
		1: ChooseAccounts,
		2: ChooseAction,
		3: Confirmation
	}
}

const SPLIT_VIEW_ID = 'accounts-split-view'

const { hasAccountsExportPermission } = useAuth()

const {
	getAllAccountsForMap,
	requestBody,
	refetchAccounts,
	tableData,
	totalItems,
	neighborhoodOptions,
	loading,
	mapboxMarkers,
	showTableMarkers,
	movingInThisMonth,
	performBatchAction,
	batchActionLoading,
	batchActionError,
	batchActionSuccess,
	skippedAccounts
} = useAccounts()
const { zones, loading: zonesLoading } = useZones()

const { getAccountStatuses, accountStatusFilterOptions } = useAccountStatus()

const openBatchActionWorkflow = ref(false)
const activePresetOptions = ref<TableFilter[]>([])
const direction = ref<'horizontal' | 'vertical'>('vertical')
const focusedZoneId = ref('')
const showMap = ref(false)
const activeMarkerId = ref('')

const isLoading = computed(() => {
	return loading.value || zonesLoading.value
})

const resetBatchSelections = ref(false)
const batchActionTableData = ref<BatchActionTableData>({ action: { id: '', label: 'Default Action' }, items: [] })
const batchActionTableDefaultColumns = ref<TableColumn[]>(ACCOUNTS_TABLE_DEFAULT_COLUMNS)
const steps = computed(() => {
	return ACCOUNTS_BATCH_ACTIONS.find((action: BatchAction) => action.id === batchActionTableData.value.action.id)?.steps || []
})
const stepComponents = computed(() => {
	return ALL_STEP_COMPONENTS?.[batchActionTableData.value.action.id] || []
})

const hasWorkflowData = computed(() => {
	return batchActionTableData.value?.items?.length && steps.value && stepComponents.value
})

watch(batchActionTableData, () => {
	openBatchActionWorkflow.value = !!batchActionTableData.value?.action?.id
}, { immediate: true, deep: true })

watch(tableData, () => { // reset batch action data when table data changes
	handleBatchActionReset()
}, { deep: true })

const setTableDataFromLasso = async (features: Feature<Geometry, GeoJsonProperties>[]) => {
	if (!features?.length) {
		await refetchAccounts({ restore: true })
		return
	}

	const stringIds = features?.map(feature => feature.properties?.id).filter(id => id).map(String) || []

	// get selected accounts from lasso and set them in batch action table data
	const lassoRequestBody: AccountRequestBody = {
		page: 1,
		limit: stringIds.length,
		sortColumn: 'id',
		sortOrder: 'ASC',
		searchValues: {
			id: stringIds
		}
	}

	await refetchAccounts({ body: lassoRequestBody, store: true })

	batchActionTableData.value.items = tableData.value || []
}

const setActiveColumnsInBatchWorkflow = (columns: TableColumn[]) => {
	batchActionTableDefaultColumns.value = columns
}

const handleBatchActionSubmit = (formData: BatchActionForm) => {
	performBatchAction(formData)
}

const handleBatchActionClose = () => {
	resetBatchSelections.value = true
	openBatchActionWorkflow.value = false
	batchActionSuccess.value = false
	batchActionError.value = false
}

const handleBatchActionReset = () => {
	batchActionTableData.value = { action: { id: '', label: 'Default Action' }, items: [] }
	batchActionTableDefaultColumns.value = ACCOUNTS_TABLE_DEFAULT_COLUMNS
	batchActionSuccess.value = false
	batchActionError.value = false
	resetBatchSelections.value = true
}

const componentAClass = computed(() => {
	return direction.value === 'horizontal' ? 'relative lg:overflow-none' : 'relative overflow-none scrollbar'
})

const componentBClass = computed(() => {
	return direction.value === 'horizontal' ? 'overflow-auto' : 'pb-4 pt-2 px-6 lg:px-8 overflow-auto scrollbar'
})

const showEmptyState = computed(() => {
	return !loading.value && !tableData.value?.length
})

const activeNeighborhood = computed(() => {
	return activePresetOptions.value.find((option: TableFilter) => option.columnKey === 'neighborhood')
})

const activeAccountStatus = computed(() => {
	return activePresetOptions.value.find((option: TableFilter) => option.columnKey === 'accountStatus')
})

const staticPresetFilters = computed(() => {
	const filtersWithActiveLabels = ACCOUNTS_TABLE_PRESET_FILTERS.map((presetFilter: PresetFilter) => {
		// add other preset filters here that you want to display with the active label
		return presetFilter
	})
	return filtersWithActiveLabels
})

const dynamicPresetFilters = computed(() => { // Preset filters that come from the API
	return [
		{
			label: activeAccountStatus.value?.label ? `Account Status: ${activeAccountStatus.value?.label}` : 'Account Status',
			id: 'accountStatus',
			options: accountStatusFilterOptions.value,
			multiSelect: false,
			allOption: true
		},
		{
			label: activeNeighborhood.value?.label ? `Neighborhood: ${activeNeighborhood.value?.label}` : 'Neighborhood',
			id: 'neighborhood',
			options: neighborhoodOptions.value,
			multiSelect: false,
			allOption: true
		}
	]
})

const presetFilters = computed(() => {
	return [
		...staticPresetFilters.value,
		...dynamicPresetFilters.value
	]
})

const setActiveMarker = (id: string) => {
	activeMarkerId.value = id
}

const handleActivePresetOptionsChanged = (options: TableFilter[] | undefined) => {
	activePresetOptions.value = [] // clear the active preset options
	if (options && options.length) {
		options?.forEach((option: TableFilter) => {
			activePresetOptions.value.push(option) // add the new active preset options
		})
	}
}

const handleRefetchAccounts = (bodyParams: AccountRequestBody) => {
	updateRequestBody(bodyParams)
	refetchAccounts({ body: requestBody.value })
}

const updateRequestBody = (bodyParams: AccountRequestBody) => {
	requestBody.value = {
		...requestBody.value,
		...bodyParams
	}
}

const isActive = (status: string): boolean => status.toLowerCase() === ACTIVE.toLowerCase()

const isTrue = (val: string) => val === 'true'

const showPriorityInstallIcon = (row: TableRow) => {
	return isTrue(row.priorityInstall) && !isTrue(row.insideInstalled) && row.accountStatus.toLowerCase() !== INACTIVE.toLowerCase()
}

watch(activeNeighborhood, () => {
	if (activeNeighborhood.value) {
		focusedZoneId.value = activeNeighborhood.value?.value || ''
	} else {
		focusedZoneId.value = ''
	}
}, { immediate: true, deep: true })

onMounted(() => {
	getAllAccountsForMap()
	getAccountStatuses()
})
</script>
