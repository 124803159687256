<template>
	<div>
		<BatchActionWorkflowStepHeader>
			<template #title>
				<!-- TODO i18n -->
				Choose Action: Step {{ stepNumber }} of {{ totalSteps }}
			</template>
			<template #description>
				<!-- TODO i18n -->
				Select the action you want to perform on <strong class="text-mx-orange">
					{{ selectedItemsCount }}
				</strong> accounts.
			</template>
			<template #content>
				<!-- radio buttons and labels for actions -->
				<AccountActionRadios
					v-model="selectedAction"
					:action-options="actionOptions"
				/>
			</template>
		</BatchActionWorkflowStepHeader>

		<div class="flex items-center justify-start w-full pt-6 bg-mx-gray-100 dark:bg-mx-green-900">
			<div class="flex items-center gap-4">
				<!-- TODO i18n -->
				<FormButton
					id="continue-button"
					button-text="Continue"
					:variant="disabled ? 'custom' : 'primary'"
					:disabled="disabled"
					button-width="w-32"
					submit
					:class="{ 'border-2 border-transparent dark:border-mx-green-800 cursor-not-allowed bg-mx-gray-200 dark:bg-mx-green-800' : disabled }"
					@submit="handleContinue"
				/>
				<!-- TODO i18n -->
				<FormButton
					id="cancel-button"
					button-text="Cancel"
					variant="secondary-background"
					button-width="w-32"
					@click="handleClose"
				/>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import type { BatchActionOption } from '@/components/table/types'

const props = defineProps({
	actionOptions: {
		type: Object as PropType<BatchActionOption[]>,
		required: true
	},
	stepNumber: {
		type: Number,
		required: true
	},
	totalSteps: {
		type: Number,
		required: true
	},
	selectedItemsCount: {
		type: Number,
		default: 0
	}
})

const { actionOptions } = toRefs(props)

const emit = defineEmits([ 'close', 'save' ])

const selectedAction = ref<string | null>(null)

const disabled = computed(() => !selectedAction.value)

const handleContinue = () => {
	emit('save', { actionOption: actionOptions.value.find(action => action.id === selectedAction.value) })
}

const handleClose = () => {
	emit('close')
}
</script>
